var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: _vm.wrapperStyle },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-center pl-4",
          staticStyle: {
            position: "absolute",
            width: "100%",
            height: "48px",
            "border-bottom": "solid 1px rgba(0, 0, 0, 0.12)"
          }
        },
        [
          _c(
            "span",
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v("info")]),
              _vm._v("Launching with "),
              _vm.createdDeployments.length &&
              _vm.createdDeployments[0].node_pool === "nv-app"
                ? _c("span", [
                    _vm._v(
                      " resource limits: " +
                        _vm._s(_vm.resourceLimit.cpu) +
                        " vCPU / " +
                        _vm._s(_vm.resourceLimit.mem) +
                        " GB memory "
                    )
                  ])
                : _c("span", [
                    _vm._v(
                      " scaled resources: " +
                        _vm._s(_vm.resourceLimit.cpu) +
                        " vCPU / " +
                        _vm._s(_vm.resourceLimit.mem) +
                        " GB memory. "
                    ),
                    _c(
                      "span",
                      { staticClass: "secondary--text font-weight-bold" },
                      [_vm._v("Launch time might be up to 5-10 minutes.")]
                    )
                  ])
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "elevation-0",
              attrs: {
                color: "secondary",
                tile: "",
                height: "48",
                disabled: _vm.sessionId === ""
              },
              on: { click: _vm.showScaleModal }
            },
            [
              _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                _vm._v("open_in_full")
              ]),
              _vm._v(" Scale resources ")
            ],
            1
          )
        ],
        1
      ),
      _vm.showLoader
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-column align-center justify-center text-center",
              staticStyle: { height: "100%" }
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v("Your application will start shortly")]),
                  _c("v-progress-linear", {
                    staticClass: "my-3",
                    attrs: {
                      indeterminate: "",
                      color: "secondary",
                      rounded: "",
                      height: "6"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-stepper",
                { attrs: { value: _vm.loadStatus } },
                [
                  _c(
                    "v-stepper-header",
                    [
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.loadStatus > 1, step: "1" } },
                        [_vm._v(" Assigning hardware ")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.loadStatus > 2, step: "2" } },
                        [_vm._v(" Preparing software ")]
                      ),
                      _c("v-divider"),
                      _c("v-stepper-step", { attrs: { step: "3" } }, [
                        _vm._v(" Loading ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-items",
                    [
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "1" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-text", [
                                _vm.nodePool
                                  ? _c("span", [
                                      _vm._v(
                                        "The appropriate hardware is being provisioned. This can take up to 3 minutes."
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "There is high demand at the moment, "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "provisioning of resources might take longer than usual. "
                                      )
                                    ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "2" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-text", [
                                _vm._v(" We are getting the software ready. "),
                                _vm.nodePool
                                  ? _c("span", [
                                      _vm._v(
                                        "This might take a few minutes on new hardware."
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-text", [
                                _vm._v(
                                  "The application is now ready and loading."
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isGuac && _vm.loadStatus < 3 && !_vm.appLongWait
                ? _c(
                    "div",
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mt-3",
                          attrs: {
                            "colored-border": "",
                            color: "blue",
                            border: "left",
                            "max-width": "500"
                          }
                        },
                        [
                          _vm._v(
                            " Hint: You can switch windows inside the application using SHIFT + TAB "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.appLongWait && _vm.nodePool === ""
                ? _c(
                    "div",
                    { staticClass: "caption mt-4 grey--text" },
                    [
                      _vm._v(
                        " If your application doesn't load in a few minutes, please try "
                      ),
                      _c("br"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-lowercase",
                          attrs: { text: "", color: "secondary", small: "" },
                          on: { click: _vm.restartApp }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("power_settings_new")]
                          ),
                          _vm._v(" restarting")
                        ],
                        1
                      ),
                      _c("div", { staticClass: "mt-2" }, [_vm._v(" or ")]),
                      _c(
                        "div",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-lowercase",
                              attrs: {
                                text: "",
                                color: "secondary",
                                small: ""
                              },
                              on: { click: _vm.askForHelp }
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-1", attrs: { small: "" } },
                                [_vm._v("support")]
                              ),
                              _vm._v("ask for help")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("iframe", {
        style: _vm.iframeStyle,
        attrs: {
          src: _vm.proxy_url,
          id: "app_iframe",
          allow: "clipboard-read; clipboard-write"
        },
        on: { load: _vm.hideLoader, mouseenter: _vm.focusIframe }
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.showCredentialModal,
            callback: function($$v) {
              _vm.showCredentialModal = $$v
            },
            expression: "showCredentialModal"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("University Login Required")]),
              _c(
                "v-card-text",
                [
                  _vm._v(" Please provide your "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("university credentials")
                  ]),
                  _vm._v(" to establish a connection to the licence server. "),
                  _c(
                    "v-alert",
                    { staticClass: "my-2", attrs: { type: "info" } },
                    [
                      _vm._v(
                        " Irrespective of how you log into Nuvolos, please provide your university credentials here. Wrong credentials will result in the licensed application not starting. "
                      )
                    ]
                  ),
                  _vm.enteredEmailAndNotUsername
                    ? _c("v-alert", { attrs: { type: "error" } }, [
                        _vm._v(
                          " It appears you entered your e-mail address instead of your login name. Please check your login name "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://my-account.unige.ch/my-account/web/home",
                              target: "_blank"
                            }
                          },
                          [_vm._v("here")]
                        ),
                        _vm._v(". ")
                      ])
                    : _vm._e(),
                  _c(
                    "v-form",
                    {
                      staticClass: "text-center",
                      attrs: { onSubmit: "return false;" },
                      on: {
                        submit: function($event) {
                          return _vm.launchApp(_vm.$route.params.aid)
                        }
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Username" },
                        model: {
                          value: _vm.appUsername,
                          callback: function($$v) {
                            _vm.appUsername = $$v
                          },
                          expression: "appUsername"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "University Password",
                          type: "password"
                        },
                        model: {
                          value: _vm.appPwd,
                          callback: function($$v) {
                            _vm.appPwd = $$v
                          },
                          expression: "appPwd"
                        }
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-2 font-weight-bold",
                          attrs: {
                            color: "secondary",
                            type: "submit",
                            width: "100%",
                            block: "",
                            disabled: _vm.enteredEmailAndNotUsername
                          }
                        },
                        [_vm._v("Launch App")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "secondary" },
                          on: { click: _vm.goToOverview }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400" },
          model: {
            value: _vm.scaleModal,
            callback: function($$v) {
              _vm.scaleModal = $$v
            },
            expression: "scaleModal"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Scale resources")]),
              _c(
                "v-card-text",
                [
                  !_vm.canScale
                    ? _c("v-alert", { attrs: { type: "info" } }, [
                        _vm._v(
                          " Application scaling is not enabled for this organization / space. Please "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "white--text",
                            attrs: { href: "#" },
                            on: { click: _vm.askForHelp }
                          },
                          [_vm._v("ask support")]
                        ),
                        _vm._v(" if you require application scaling. ")
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "my-2" }, [
                    _vm._v(
                      " Select the required resource requirements - the application will then be restarted. "
                    )
                  ]),
                  _c(
                    "v-tabs",
                    {
                      model: {
                        value: _vm.nodeFamilySelected,
                        callback: function($$v) {
                          _vm.nodeFamilySelected = $$v
                        },
                        expression: "nodeFamilySelected"
                      }
                    },
                    [
                      _c("v-tab", { key: "cpu" }, [_vm._v("CPU nodes")]),
                      _c("v-tab", { key: "gpu" }, [_vm._v("GPU nodes")])
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.nodeFamilySelected,
                        callback: function($$v) {
                          _vm.nodeFamilySelected = $$v
                        },
                        expression: "nodeFamilySelected"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        { key: "cpu" },
                        _vm._l(
                          _vm.instanceTypes.filter(function(i) {
                            return !i.gpu
                          }),
                          function(instance) {
                            return _c(
                              "div",
                              { key: instance.nodePool },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mt-4 text-none elevation-0",
                                    attrs: {
                                      block: "",
                                      color: "secondary",
                                      disabled: !_vm.canScale,
                                      tile: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectNodePool(
                                          instance.nodePool
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(instance.cpu) +
                                          " vCPU / " +
                                          _vm._s(instance.mem) +
                                          " GB mem "
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "caption primary--text text-center my-1"
                                  },
                                  [
                                    _vm._v(" Price: "),
                                    _c("b", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            instance.node_hour.toFixed(2)
                                          ) +
                                          " credits / hour,"
                                      )
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (24 * instance.node_hour).toFixed(2)
                                        ) +
                                        " credits / day "
                                    )
                                  ]
                                ),
                                _c("v-divider")
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                      _c(
                        "v-tab-item",
                        { key: "gpu" },
                        _vm._l(
                          _vm.instanceTypes.filter(function(i) {
                            return i.gpu
                          }),
                          function(instance) {
                            return _c(
                              "div",
                              { key: instance.nodePool },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mt-4 text-none elevation-0",
                                    attrs: {
                                      block: "",
                                      color: "secondary",
                                      disabled: !_vm.canScale,
                                      tile: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectNodePool(
                                          instance.nodePool
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(instance.cpu) +
                                          " vCPU / " +
                                          _vm._s(instance.mem) +
                                          " GB mem / " +
                                          _vm._s(instance.gpu) +
                                          " GPU "
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "caption primary--text text-center my-1"
                                  },
                                  [
                                    _vm._v(" Price: "),
                                    _c("b", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            instance.node_hour.toFixed(2)
                                          ) +
                                          " credits / hour,"
                                      )
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (24 * instance.node_hour).toFixed(2)
                                        ) +
                                        " credits / day "
                                    )
                                  ]
                                ),
                                _c("v-divider")
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.hideScaleModal } },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }