<template>
    <div :style="wrapperStyle">
        <div class="d-flex align-center pl-4" style="position:absolute; width: 100%; height: 48px; border-bottom: solid 1px rgba(0, 0, 0, 0.12)">
            <span>
                <v-icon class="mr-2">info</v-icon>Launching with
                <span v-if="createdDeployments.length && createdDeployments[0].node_pool === 'nv-app'">
                    resource limits: {{ resourceLimit.cpu }} vCPU / {{ resourceLimit.mem }} GB memory </span
                ><span v-else>
                    scaled resources: {{ resourceLimit.cpu }} vCPU / {{ resourceLimit.mem }} GB memory.
                    <span class="secondary--text font-weight-bold">Launch time might be up to 5-10 minutes.</span></span
                >
            </span>
            <v-spacer> </v-spacer>
            <v-btn color="secondary" @click="showScaleModal" tile height="48" class="elevation-0" :disabled="sessionId === ''">
                <v-icon class="mr-1" small>open_in_full</v-icon>
                Scale resources
            </v-btn>
        </div>
        <div v-if="showLoader" class="d-flex flex-column align-center justify-center text-center" style="height: 100%">
            <div>
                <p>Your application will start shortly</p>
                <v-progress-linear indeterminate color="secondary" rounded height="6" class="my-3"></v-progress-linear>
            </div>
            <v-stepper :value="loadStatus">
                <v-stepper-header>
                    <v-stepper-step :complete="loadStatus > 1" step="1">
                        Assigning hardware
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="loadStatus > 2" step="2">
                        Preparing software
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Loading
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <span v-if="nodePool">The appropriate hardware is being provisioned. This can take up to 3 minutes.</span>
                                <span v-else>There is high demand at the moment, <br />provisioning of resources might take longer than usual. </span>
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card
                            ><v-card-text>
                                We are getting the software ready.
                                <span v-if="nodePool">This might take a few minutes on new hardware.</span>
                            </v-card-text></v-card
                        >
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-card> <v-card-text>The application is now ready and loading.</v-card-text></v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
            <div v-if="isGuac && loadStatus < 3 && !appLongWait">
                <v-alert class="mt-3" colored-border color="blue" border="left" max-width="500">
                    Hint: You can switch windows inside the application using SHIFT + TAB
                </v-alert>
            </div>
            <div class="caption mt-4 grey--text" v-if="appLongWait && nodePool === ''">
                If your application doesn't load in a few minutes, please try
                <br />
                <v-btn @click="restartApp" text color="secondary" class="text-lowercase" small
                    ><v-icon small class="mr-1">power_settings_new</v-icon> restarting</v-btn
                >
                <div class="mt-2">
                    or
                </div>
                <div class="mt-2">
                    <v-btn @click="askForHelp" text color="secondary" class="text-lowercase" small
                        ><v-icon small class="mr-1">support</v-icon>ask for help</v-btn
                    >
                </div>
            </div>
        </div>
        <iframe :src="proxy_url" :style="iframeStyle" @load="hideLoader" id="app_iframe" @mouseenter="focusIframe" allow="clipboard-read; clipboard-write" />
        <v-dialog v-model="showCredentialModal" max-width="400">
            <v-card>
                <v-card-title>University Login Required</v-card-title>
                <v-card-text>
                    Please provide your <span class="font-weight-bold">university credentials</span> to establish a connection to the licence server.
                    <v-alert type="info" class="my-2">
                        Irrespective of how you log into Nuvolos, please provide your university credentials here. Wrong credentials will result in the licensed
                        application not starting.
                    </v-alert>
                    <v-alert type="error" v-if="enteredEmailAndNotUsername">
                        It appears you entered your e-mail address instead of your login name. Please check your login name
                        <a href="https://my-account.unige.ch/my-account/web/home" target="_blank">here</a>.
                    </v-alert>
                    <v-form @submit="launchApp($route.params.aid)" onSubmit="return false;" class="text-center">
                        <v-text-field label="Username" v-model="appUsername"></v-text-field>
                        <v-text-field v-model="appPwd" label="University Password" type="password"></v-text-field>
                        <v-btn class="mt-2 font-weight-bold" color="secondary" type="submit" width="100%" block :disabled="enteredEmailAndNotUsername"
                            >Launch App</v-btn
                        >
                        <v-btn text color="secondary" @click="goToOverview">Cancel</v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="scaleModal" persistent max-width="400">
            <v-card>
                <v-card-title>Scale resources</v-card-title>
                <v-card-text>
                    <v-alert type="info" v-if="!canScale">
                        Application scaling is not enabled for this organization / space. Please
                        <a href="#" @click="askForHelp" class="white--text">ask support</a> if you require application scaling.
                    </v-alert>
                    <div class="my-2">
                        Select the required resource requirements - the application will then be restarted.
                    </div>
                    <v-tabs v-model="nodeFamilySelected">
                        <v-tab key="cpu">CPU nodes</v-tab>
                        <v-tab key="gpu">GPU nodes</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="nodeFamilySelected">
                        <v-tab-item key="cpu">
                            <div v-for="instance in instanceTypes.filter(i => !i.gpu)" :key="instance.nodePool">
                                <v-btn
                                    block
                                    color="secondary"
                                    class="mt-4 text-none elevation-0"
                                    :disabled="!canScale"
                                    @click="selectNodePool(instance.nodePool)"
                                    tile
                                >
                                    <span> {{ instance.cpu }} vCPU / {{ instance.mem }} GB mem </span>
                                </v-btn>
                                <div class="caption primary--text text-center my-1">
                                    Price: <b> {{ instance.node_hour.toFixed(2) }} credits / hour,</b> {{ (24 * instance.node_hour).toFixed(2) }} credits / day
                                </div>
                                <v-divider />
                            </div>
                        </v-tab-item>
                        <v-tab-item key="gpu">
                            <div v-for="instance in instanceTypes.filter(i => i.gpu)" :key="instance.nodePool">
                                <v-btn
                                    block
                                    color="secondary"
                                    class="mt-4 text-none elevation-0"
                                    :disabled="!canScale"
                                    @click="selectNodePool(instance.nodePool)"
                                    tile
                                >
                                    <span> {{ instance.cpu }} vCPU / {{ instance.mem }} GB mem / {{ instance.gpu }} GPU </span>
                                </v-btn>
                                <div class="caption primary--text text-center my-1">
                                    Price: <b> {{ instance.node_hour.toFixed(2) }} credits / hour,</b> {{ (24 * instance.node_hour).toFixed(2) }} credits / day
                                </div>
                                <v-divider />
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                <v-card-actions><v-spacer /><v-btn text @click="hideScaleModal">Cancel</v-btn> </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'

export default {
    mixins: [appTypeAndImageLink],
    data() {
        return {
            currentSessionId: '',
            cookieTimer: null,
            cookieVal: null,
            showLoader: true,
            aid: '0',
            loadedSessionId: '',
            isChromiumBased: window.chrome,
            appLongWait: false,
            showCredentialModal: false,
            appUsername: '',
            appPwd: '',
            nodePool: '',
            longAppLoad: null,
            scaleModal: false,
            canScale: false,
            instanceTypes: [],
            loadingAppTimeout: null,
            nodeFamilySelected: 0
        }
    },
    methods: {
        getCookie(sessionId) {
            const aid = this.$data.aid
            if (aid > 0) {
                return this.$axios.get(`/proxy/${aid}/get_cookie?session_id=${sessionId}`, { withCredentials: true }).catch(error => {
                    console.log(error)
                })
            }
        },
        hideLoader(event) {
            if (this.proxy_url) {
                this.$axios.post(`/applications/${this.$data.aid}/app_loaded`).catch(error => {
                    console.log(error)
                })
                window.setTimeout(() => {
                    this.$data.showLoader = false
                    this.$nextTick(() => {
                        this.focusIframe()
                    })
                    if (this.isChromiumBased && this.isGuac) {
                        navigator.clipboard
                            .readText()
                            .then(clipText => {
                                navigator.clipboard.writeText(clipText).catch(err => {
                                    this.showErrModal(err)
                                })
                            })
                            .catch(err => {
                                this.showErrModal(err)
                            })
                            .finally(() => {
                                this.focusIframe()
                            })
                    }
                }, 1000)
            }
        },
        restartApp() {
            this.$store.dispatch('appStore/stopApp', { aid: this.$data.aid }).then(() => {
                location.reload()
            })
        },
        focusIframe() {
            if (this.$route.name === 'app-open' && !this.showCredentialModal && !this.scaleModal) {
                document.getElementsByTagName('main')[0].scrollTop = 0
                if (document.getElementById('app_iframe')) {
                    document.getElementById('app_iframe').blur()
                    document.getElementById('app_iframe').focus()
                }
            }
        },
        showErrModal(err) {
            console.log(err)
            if (document.hasFocus()) {
                this.$store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Enable clipboard access',
                    dialogText: 'Please enable clipboard access for Nuvolos, otherwise copy-paste will not work properly',
                    dialogAction: 'dismiss'
                })
            }
        },
        askForHelp() {
            window.Intercom('show')
        },
        goToOverview() {
            this.$router.push({
                name: 'snapshot-overview',
                params: { oid: this.$route.params.oid, sid: this.$route.params.sid, iid: this.$route.params.iid, snid: this.$route.params.snid }
            })
            this.$data.showCredentialModal = false
        },
        launchApp(aid, checkIfAppRunning = true) {
            this.$data.currentSessionId = null
            if (this.$data.longAppLoad) {
                clearTimeout(this.$data.longAppLoad)
            }
            this.$data.appLongWait = false
            this.$data.longAppLoad = setTimeout(() => {
                this.$data.appLongWait = true
            }, 300000)

            const to = aid.toString()
            this.$data.aid = to.toString()
            this.focusIframe()
            this.$store.dispatch('appStore/setCurrentAppType', { iid: this.$route.params.iid, aid: to }).then(() =>
                this.$store.dispatch('appStore/updateDeploymentStatus').then(() => {
                    if (this.runningDeployments.length > 0) {
                        this.loadSession(this.runningDeployments[0].session_id)
                    } else {
                        this.$data.showLoader = true
                        if (this.createdDeployments.length === 0 || !checkIfAppRunning) {
                            this.$data.showCredentialModal = false
                            // need to start the application - let the user know
                            this.$store
                                .dispatch('appStore/startApp', {
                                    aid: to,
                                    nodePool: this.$data.nodePool,
                                    appUsername: this.$data.appUsername,
                                    appPwd: this.$data.appPwd
                                })
                                .catch(e => {
                                    this.$data.showCredentialModal = true
                                })
                                .then(() => {
                                    this.loadSession(this.$store.state.appStore.lastStartedSessionId)
                                })
                        }
                    }
                })
            )
        },
        hideScaleModal() {
            this.$router.push({
                name: 'app-open',
                params: this.$route.params,
                query: { scale: 0 }
            })
            this.$data.scaleModal = false
        },
        showScaleModal() {
            this.$data.scaleModal = true
        },
        selectNodePool(np) {
            this.hideScaleModal()
            this.$data.nodePool = np
            this.$store.dispatch('showSnackBar', { snackBarText: 'Stopping previous configuration...' })
            this.$store.dispatch('appStore/stopApp', { aid: this.$route.params.aid, sessionId: this.createdDeployments[0].session_id }).then(() => {
                this.launchApp(this.$route.params.aid, false)
            })
            this.$router.push({
                name: 'app-open',
                params: this.$route.params,
                query: { scale: np }
            })
        },
        loadSession(sessionId) {
            if (sessionId && sessionId !== this.$data.loadedSessionId) {
                this.$data.showLoader = true
                this.getCookie(sessionId).then(() => {
                    window.setTimeout(() => {
                        this.$data.loadedSessionId = sessionId
                    }, 1000)
                })
            } else {
                this.$data.loadedSessionId = sessionId
            }
        }
    },
    mounted() {
        this.$data.cookieTimer = setInterval(() => {
            if (document.hasFocus() && this.runningDeployments.length > 0) {
                this.getCookie(this.runningDeployments[0].session_id)
            }
        }, 1 * 60000)
        window.addEventListener('focus', () => {
            this.focusIframe()
        })
    },
    beforeDestroy() {
        clearInterval(this.$data.cookieTimer)
    },
    computed: {
        sessionId() {
            if (this.createdDeployments.length) {
                return this.$data.currentSessionId || this.createdDeployments[0].session_id
            }
            return this.$data.currentSessionId
        },
        fileOpened() {
            const aid = this.$data.aid
            if (this.$route.params.filePath) {
                return '/' + this.$route.params.filePath
            }
            return this.$store.state.snapshotStore.fileOpened[aid]
        },
        runningDeployments() {
            return this.deploymentStatus.filter(d => {
                return d.aid.toString() === this.$data.aid && parseInt(d.available_replicas, 10) > 0
            })
        },
        createdDeployments() {
            return this.deploymentStatus.filter(d => {
                return d.aid.toString() === this.$data.aid
            })
        },
        proxy_url() {
            if (this.$data.loadedSessionId && this.runningDeployments.length) {
                const openedFile =
                    this.fileOpened !== undefined && this.appType === 'jupyterlab'
                        ? (this.$route.params.filePath && this.$route.params.filePath.endsWith('.ipynb') ? '/tree' : '/lab/tree') + this.fileOpened
                        : ''

                const apexDomain = '.nuvolos.cloud'
                const hostToProxyMap = {
                    'nuvolos.cloud': 'proxy.',
                    'staging.nuvolos.cloud': 'proxy-staging',
                    'dh-test.nuvolos.cloud:8080': 'proxy.nginx',
                    'az-staging.nuvolos.cloud': 'proxy.nginx',
                    'www.nuvolos.cloud': 'app.az',
                    'az.nuvolos.cloud': 'app.az'
                }

                return (
                    'https://' + this.$data.loadedSessionId + '.' + hostToProxyMap[location.host] + apexDomain + openedFile + (this.isHiDPI ? '?hidpi=1' : '')
                )
            }
            return ''
        },
        wrapperStyle() {
            return {
                height: '100%',
                width: '100%',
                'overflow-x': 'hidden',
                'overflow-y': 'hidden'
            }
        },
        iframeStyle() {
            // handle hiDPI by scaling the iframe
            if (this.createdDeployments.length && this.createdDeployments[0].DPI !== '96') {
                return {
                    width: '200%',
                    height: '200%',
                    border: '0',
                    transform: 'scale(0.5)',
                    'transform-origin': 'top left',
                    opacity: this.$data.showLoader ? 0 : 1,
                    position: 'absolute',
                    'z-index': this.$data.showLoader ? -1 : 'auto',
                    left: '0px'
                }
            }
            return {
                width: '100%',
                height: '100%',
                border: '0',
                opacity: this.$data.showLoader ? 0 : 1,
                position: 'absolute',
                'z-index': this.$data.showLoader ? -1 : 'auto',
                left: '0px'
            }
        },
        isGuac() {
            return ['stata', 'spyder', 'octave', 'matlab', 'spss', 'vscode'].indexOf(this.getAppType(this.appType)) !== -1
        },
        resourceLimit() {
            const d = this.deploymentStatus.filter(de => this.$route.params.aid && de.aid.toString() === this.$route.params.aid.toString())
            if (d.length && d[0].limit_cpu !== 'N/A') {
                return { cpu: parseInt(d[0].limit_cpu.replace('m', '')) / 1000, mem: parseInt(d[0].limit_memory.replace('M', '')) / 1000 }
            }
            return { cpu: 0, mem: 0 }
        },
        loadStatus() {
            if (this.runningDeployments.length) {
                return 3
            } else if (this.createdDeployments.length && this.createdDeployments[0].pod_status) {
                const podStatus = this.createdDeployments[0].pod_status.filter(ps => ps.type === 'PodScheduled')
                if (podStatus.length && podStatus[0].status === 'True') {
                    return 2
                } else {
                    return 1
                }
            }
            return 1
        },
        showClipboardWarning() {
            return this.isGuac && !this.$data.isChromiumBased && this.$route.name === 'app-open'
        },
        enteredEmailAndNotUsername() {
            return this.$data.appUsername && this.$data.appUsername.endsWith('unige.ch')
        },
        ...mapState('appStore', ['deploymentStatus', 'isHiDPI', 'appType']),
        ...mapState('orgStore', ['spacesWithPrimarySnapshots']),
        ...mapState(['userOrgs'])
    },
    watch: {
        $route: {
            handler: function(to, from) {
                if (to.params.aid) {
                    if (to.query && to.query.scale) {
                        if (to.query.scale.toString() === '1') {
                            this.showScaleModal()
                            this.$data.nodePool = ''
                        } else if (to.query.scale.toString() !== '0') {
                            this.$data.nodePool = to.query.scale
                        } else {
                            this.$data.nodePool = ''
                        }
                    } else {
                        this.$data.nodePool = ''
                    }
                    this.$axios.get(`/apps/${to.params.aid}/nodepools`).then(r => {
                        this.$data.instanceTypes = Object.entries(r.data).map(np => {
                            return { nodePool: np[0], cpu: np[1].limit.cpu, mem: np[1].limit.memory, gpu: np[1].gpu_type, node_hour: np[1].node_hour }
                        })
                    })
                    this.launchApp(to.params.aid, true)
                    this.$axios.post('/spaces/find', { sid: this.$route.params.sid }).then(r => {
                        this.$data.canScale = r.data.length && r.data[0].beegfs_sync
                    })
                } else {
                    // if user navigated away from app, then clear timeout
                    if (this.$data.loadingAppTimeout) {
                        clearTimeout(this.$data.loadingAppTimeout)
                    }
                }
            },
            immediate: true
        },
        fileOpened(to, from) {
            if (to) {
                if (this.appType === 'jupyterlab') {
                    this.$data.showLoader = true
                } else {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Please use the File open feature of the application to open /files' + to,
                        snackBarIcon: 'info'
                    })
                }
            }
        },
        runningDeployments: {
            handler: function(to, from) {
                if (to.length > 0) {
                    this.loadSession(to[0].session_id)
                } else {
                    this.loadSession('')
                    if (from && from.length > 0 && this.$route.name === 'app-open' && from[0].aid.toString() === this.$route.params.aid.toString()) {
                        this.$store.dispatch('showGlobalDialog', {
                            dialogTitle: 'Application Stopped',
                            dialogText: 'This application was stopped due to inactivity or exceeding available resources.',
                            dialogAction: 'returnToWelcomePage'
                        })
                    }
                }
            },
            immediate: true
        },
        appType(to, from) {
            if (to === 'sas') {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Log in with username / password: nuvolos / cloud',
                    snackBarTimeout: 20000
                })
            }
        },
        loadStatus(to, from) {
            // handle sometimes stuck loader - TODO check why this happens in Guac
            if (to === 3) {
                if (this.$data.loadingAppTimeout) {
                    clearTimeout(this.$data.loadingAppTimeout)
                }
                this.$data.loadingAppTimeout = window.setTimeout(() => {
                    if (this.$data.showLoader && this.$route.name === 'app-open') {
                        location.reload()
                    }
                }, 30000)
            }
        },
        showClipboardWarning: {
            handler: function(to, from) {
                if (to) {
                    this.$store.dispatch('showGlobalDialog', {
                        dialogTitle: 'Change browser for clipboard support',
                        dialogText: 'Copy-Paste for this application is only fully supported on Chrome or Edge (Chromium) browsers.',
                        dialogAction: 'dismiss'
                    })
                }
            },
            immediate: true
        }
    }
}
</script>
